import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import CourseCard from "./CourseCard";
import "./sections/trending_courses.scss";
import {
  SENIOR_COACH,
  INTERNSHIP_PROGRAM_PATH,
  INTERNSHIP_PROGRAM,
  SKILL_COURSE,
  SKILL_COURSE_PATH,
} from "../utils/localization";
import { isSSR } from "../helpers/global";

export const TrendingCourses = ({ courses, name, series }) => {
  const containerRef = useRef();

  const [percentage, setPercentage] = useState(0);

  const onScroll = () => {
    const maxWidth = containerRef.current.clientWidth;
    const minWidth = containerRef.current.scrollWidth;
    const minPercentage = (maxWidth / minWidth) * 100;
    setPercentage(minPercentage);
    let percentage =
      (100 * containerRef.current.scrollLeft) /
      (containerRef.current.scrollWidth - containerRef.current.clientWidth);
    setPercentage(percentage - 10);
  };

  useEffect(() => {
    if (window && containerRef) {
      onScroll();
    }
  }, []);

  const getOutLink = (slug, productLine) => {
    if (!isSSR()) {
      return getCoursePath(slug, productLine);
    }
  };

  const getCoursePath = (slug, productLine) => {
    if (productLine === INTERNSHIP_PROGRAM) {
      return `/${INTERNSHIP_PROGRAM_PATH}/${slug}`;
    } else if (productLine === SKILL_COURSE) {
      return `/${SKILL_COURSE_PATH}/${slug}`;
    } else {
      return `/404`;
    }
  };

  return (
    <div id="trending-section" className="trending-courses-section">
      <h2 id="trending-section-heading">{name}</h2>
      <div
        id="trending-section-courses-container"
        className="courses-list-container"
        onScroll={onScroll}
        ref={containerRef}
      >
        {courses?.map((course) => {
          const outLink = getOutLink(course?.slug, course?.productLine);
          return (
            <CourseCard
              series={series}
              key={course?.slug}
              title={course?.title}
              instructor={course?.coaches?.find(
                (coach) => coach?.userType === SENIOR_COACH
              )}
              price={course?.price}
              link={outLink}
              description={course?.description}
              shortDescription={course?.shortDescription}
              card_image={course?.coverImage}
              coursesIncludes={course?.courses ?? []}
              productLine={course?.productLine}
            />
          );
        })}
        <div className="course-list-space">&nbsp;</div>
      </div>
      <div className={"courses-scroll-container"}>
        <button
          className={`courses-scroll-button`}
          onClick={() => {
            containerRef.current.scrollLeft -= 600;
          }}
        >
          <span>{"<"}</span>
        </button>
        <button
          className={`courses-scroll-button`}
          onClick={() => {
            containerRef.current.scrollLeft += 600;
          }}
        >
          <span>{">"}</span>
        </button>
        <div className="trending-courses-slide-progress-wrapper">
          <div
            className="trending-courses-slider-progress"
            style={{
              marginLeft: `${percentage}%`,
            }}
          />
        </div>
      </div>
    </div>
  );
};

TrendingCourses.propTypes = {
  courses: PropTypes.array,
  link: PropTypes.string,
};

export default TrendingCourses;
