import React, { useEffect } from "react";
import { navigate } from "gatsby";
import Layout from "../../components/Layout";
import TrendingCourses from "../../components/TrendingCourses";
import { isEmpty } from "lodash";

const CategoryPage = (props) => {
  const { pageContext } = props;
  const { category } = pageContext;
  let pageTitle, pageDescription, pageSchema;
  pageTitle = category?.title;

  useEffect(() => {
    if (isEmpty(pageContext)) {
      navigate("/404");
    }
  });

  return (
    <Layout
      title={pageTitle}
      description={pageDescription}
      schemaMarkup={pageSchema}
    >
      <h1 id={"category-name"}>{category?.name}</h1>
      <TrendingCourses
        courses={category?.courses?.nodes}
        name={`All ${category?.name} Courses`}
      />
    </Layout>
  );
};

export default CategoryPage;
