import React, { Fragment } from "react";
import Img from "gatsby-image";
import ProfileAvatar from "./ProfileAvatar";
import PropTypes from "prop-types";
import SeriesCardTag from "../img/images/series-card-tag.svg";
import { IMAGE_PLACEHOLDER, SKILL_COURSE } from "../utils/localization";

export const CourseCard = ({
  title,
  price,
  instructor,
  link,
  description,
  card_image,
  series,
  coursesIncludes,
  shortDescription,
  productLine,
}) => {
  const onClick = () => {
    window.location = link;
  };

  const coursesIncludesCount = coursesIncludes.length;
  return (
    <div className="course-card" onClick={onClick}>
      {series && (
        <img src={SeriesCardTag} className="series-card-tag" alt="series tag" />
      )}
      <div className={"course-card-top-container"}>
        <Img
          className="course-card-image"
          fluid={{ src: card_image?.url ?? IMAGE_PLACEHOLDER }}
          alt="Course Card Image"
        />
        <div className="course-card-content-container">
          <h3 className="course-card-title">{title}</h3>
          {productLine !== SKILL_COURSE && (
            <h4 className="course-card-price">{price && `₹ ${price}`}</h4>
          )}
          <div className="course-card-instructor-container">
            {series ? (
              <h5 className="courses-includes">
                Includes {coursesIncludesCount} courses
              </h5>
            ) : (
              <Fragment>
                <ProfileAvatar
                  size={32}
                  customClass="coach-img"
                  profilePicture={instructor?.profilePicture}
                  firstName={instructor?.firstName}
                  lastName={instructor?.lastName}
                />
                <h5 className="course-card-instructor-name">
                  {instructor?.firstName + " " + instructor?.lastName}
                </h5>
              </Fragment>
            )}
          </div>
        </div>
      </div>
      <div className={"course-card-bottom-container"}>
        <p>{series ? shortDescription : description}</p>
        <button className={"explore-course-button"}>Explore Course</button>
      </div>
    </div>
  );
};

CourseCard.propTypes = {
  title: PropTypes.string,
  price: PropTypes.number,
  instructor: PropTypes.object,
  featuredImage: PropTypes.object,
  link: PropTypes.string,
  description: PropTypes.string,
  card_image: PropTypes.object,
  shortDescription: PropTypes.string,
};

export default CourseCard;
